export default {
  error_occurred: 'Désolé, une erreur est survenue. Veuillez réessayer dans un instant.',
  new_content_available: 'Une nouvelle mise à jour d\'un site web est disponible. Veuillez cliquer sur "Actualiser" pour recharger la page.',
  refresh_button_title: 'Actualiser',
  force_reload_message: 'Désolé, une erreur est survenue. Notre équipe travaille déjà à sa suppression. Essayez de recharger la page.',
  sign_up: 'Créer un compte',
  service: 'Service',
  employee: 'Spécialiste',
  select_employee: 'Choisir une personne',
  employees: 'Spécialistes',
  services: 'Services',
  duration: 'Durée',
  from: 'De',
  select_date: 'Choisir une date',
  no_account_question: 'Vous n’avez pas encore de compte?',
  name: 'Prénom',
  surname: 'Nom',
  name_and_surname: 'Nom et prénom',
  phone: 'Téléphone',
  password: 'Mot de passe',
  field_is_required: 'Champ obligatoire.',
  value_is_too_long: 'Valeur trop longue.',
  value_is_too_short: 'Valeur trop courte.',
  signup_was_successful: 'L\'inscription effectuée avec succès. La connexion au panneau est en cours.',
  signup_has_failed: 'Échec de l\'inscription. Veuillez réessayer dans un instant.',
  email_is_taken: 'Un compte avec l\'adresse email indiquée existe déjà. Si vous ne vous rappelez pas votre mot de passe, réinitialisez-le maintenant.',
  email_or_password_incorrect: 'L\'adresse email ou le mot de passe que vous avez entré est incorrect. Si vous ne vous rappelez pas votre mot de passe, réinitialisez-le maintenant.',
  account_not_found: 'Impossible de trouver votre compte.',
  page_not_found: 'Oups! Impossible de trouver un site web :(',
  go_back_to_main_page: 'Veuillez retourner à la page d\'accueil et réessayer.',
  bookings: 'Réservations',
  account: 'Mon compte',
  date: 'Date',
  cancel: 'Annuler',
  date_from: 'Depuis la date du',
  date_to: 'Jusqu’à la date du',
  nothing_found_here: 'Aucun résultat.',
  old_password: 'Ancien mot de passe',
  remove: 'Supprimer',
  phone_is_invalid: 'Le numéro de téléphone n\'est pas correct.',
  book: 'Réserver',
  sign_up_2: 'Créer un compte',
  log_in: 'Connexion',
  your_email_address: 'Votre adresse mail',
  select: 'Valider',
  filters: 'Filtres',
  expand: 'Dérouler',
  collapse: 'Replier',
  bookings_drawer_title: 'Sélectionnez les filtres pour afficher uniquement les réservations que vous recherchez.',
  clear_filters: 'Effacer les filtres',
  show_more: 'En savoir plus',
  dont_you_remember_password: 'Mot de Passe oublié ?',
  password_information: 'Un mot de passe doit contenir au minimum 8 caractères, une majuscule, un chiffre et un signe spécial.',
  forgotten_password_button: 'Envoyer un lien pour modifier le mot de passe',
  do_you_already_have_an_account: 'Vous avez déjà un compte ?',
  reset_password_title: 'Définissez un nouveau mot de passe',
  reset_password_subtitle: 'Entrez votre nouveau mot de passe ci-dessous.',
  reset_password_button: 'Enregistrer le nouveau mot de passe',
  profile: 'Profil',
  logout: 'Déconnexion',
  cancel_booking: 'Annuler la reservation',
  status_approved: 'Acceptée',
  status_payment: 'En attente de paiement',
  status_done: 'Complété',
  status_cancelled: 'Annulé',
  cancel_booking_dialog_title: 'Voulez-vous annuler votre réservation?',
  cancel_booking_dialog_information: 'Cette action ne peut pas être annulée!',
  cancel_booking_dialog_button: 'Oui, annuler la réservation',
  my_profile: 'Mon Profil',
  my_data: 'Mes détails',
  upload_new_photo: 'Ajouter une nouvelle image',
  birthday: 'Anniversaire',
  save_changes: 'Enregistrer les modifications',
  phone_number: 'Numéro de téléphone',
  directional: 'Indicatif pays',
  save_new_password: 'Enregistrer le nouveau mot de passe',
  new_password: 'Nouveau mot de passe',
  password_change: 'Changer le mot de passe',
  view: 'Consulter',
  services_2: 'Services',
  accept_terms_and_conditions_1: 'En cliquant sur une option ci-dessous, j\'accepte',
  accept_terms_and_conditions_2: 'conditions générales d\'utilisation',
  accept_terms_and_conditions_3: 'et je confirme que j\'ai lu et j\'accepte',
  accept_terms_and_conditions_4: 'charte de confidentialité.',
  to: 'À',
  pln: '@{value} zł',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Connexion',
  email_is_incorrect: 'L\'adresse email est incorrecte.',
  logged_out: 'Vous avez été déconnecté',
  booking_dialog_date_is_already_taken: 'La date choisie n\'est pas disponible. Merci d\'en sélectionner une autre.',
  data_search: 'Chargement des données. Veuillez patienter...',
  no_employees: 'Aucun spécialiste n\'est disponible',
  no_services: 'Aucun service n\'est disponible',
  no_categories: 'Aucune catégorie n\'est disponible',
  no_bookings: 'Aucune réservation n\'a pas été trouvée.',
  no_subscriptions: 'Aucune souscription n\'a pas été trouvée.',
  female: 'Femme',
  male: 'Homme',
  other: 'Autre',
  no_numeric_in_password: 'Un mot de passe doit contenir au moins un chiffre.',
  no_lowercase_in_password: 'Un mot de passe doit contenir au moins une minuscule.',
  no_uppercase_in_password: 'Un mot de passe doit contenir au moins une majuscule.',
  no_special_character: 'Un mot de passe doit contenir au moins un caractère spécial.',
  min_n_characters: 'La longueur minimale est de @{min} caractères.',
  max_n_characters: 'La longueur maximale est de @{max} caractères.',
  email: 'Email',
  reset_password_fail_text: 'Le mot de passe n\'a pas été changé. Le lien a probablement expiré.',
  reset_password_success_title: 'Le mot de passe a été changé.',
  reset_password_success_text: 'Vous pouvez vous connecter avec votre nouveau mot de passe.',
  account_activation: 'Activation du compte',
  account_activation_success: 'Le compte a été activé.',
  account_activation_fail: 'Un problème est survenu lors de l\'activation de votre compte. Il semble que le compte est déjà actif ou que le lien a expiré.',
  account_activation_processing: 'Le compte est en cours d\'activation. Veuillez patienter...',
  account_activation_resend_title: 'Votre compte n\'a pas encore été activé',
  account_activation_resend_text: 'Activez le compte en cliquant sur le lien d\'activation dans l\'email envoyé.',
  account_activation_resend_button: 'Renvoyer',
  confirm_send_activate_link_dialog_subtitle: 'Nous avons envoyé un message avec les instructions pour modifier le mot de passe à l’adresse que vous avez indiquée.',
  file_is_too_large: 'Le fichier est trop grand.',
  appointment_title: 'Sélectionnez la personne, la date et l\'heure de la réservation',
  others: 'Autres',
  countryLabel: 'Pays',
  requiredMessages: 'Numéro de téléphone requis',
  date_of_visit: 'Date',
  hour_of_visit: 'Heure',
  thank_you_for_booking: 'Votre réservation',
  no_free_hours: 'Il n\'y a pas de dates disponibles pour le jour sélectionné.',
  any_person: 'Choix aléatoire',
  password_changed: 'Le mot de passe a été changé',
  incorrect_old_password: 'Ancien mot de passe incorrect',
  error404: 'Erreur 404',
  minutes: 'min',
  hours: 'h',
  days: 'j',
  ok: 'OK',
  web_page_temporary_disabled: 'Nous sommes désolés. Le site web est temporairement désactivé.',
  profile_update_success: 'Vos données ont été enregistrées.',
  form_is_invalid: 'Le formulaire contient des erreurs. Vérifiez les champs en surbrillance et réessayez.',
  search: 'Recherche',
  pay_online_button_title: 'Payer en ligne',
  account_not_activated_info: 'L\'adresse e-mail n\'a pas encore été confirmée. Confirmez votre adresse e-mail en cliquant sur le lien dans le message envoyé.',
  paid: 'Payé',
  payments: 'Facturation',
  payment_service: 'Service',
  payment_date: 'Date',
  payment_price: 'Prix',
  payment_success_title: 'Votre paiement a été effectué avec succès, merci!',
  payment_fail_title: 'Échec paiement, veuillez réessayer.',
  pay_again: 'Payer à nouveau',
  newsletter_subscribe_success: 'Merci, votre adresse email a été confirmée.',
  newsletter_subscribe_fail: 'Un problème est survenu lors de l\'activation de votre adresse email. Il semble que l\'adresse email a déjà été confirmée ou que le lien a expiré.',
  newsletter_send_again: 'Renvoyer',
  newsletter_send_again_success: 'Le lien d\'activation a été envoyé à nouveau.',
  send_again_fail: 'Un problème est survenu lors de la génération d\'un nouveau lien d\'activation. Peut-être que votre adresse e-mail est déjà active ou que vous venez de générer le lien.',
  newsletter_email_is_used: 'L\'adresse e-mail est déjà sur la liste. Si l\'adresse n\'a pas été confirmée, nous pouvons renvoyer le lien d\'activation.',
  newsletter_unsubscribe_success: 'Merci, votre adresse email a été supprimée.',
  newsletter_unsubscribe_fail: 'Un problème est survenu lors de la suppression de l\'adresse e-mail. Il semble que l\'adresse e-mail a déjà été supprimée ou que le lien a expiré.',
  booking_payments: 'Réservations',
  simple_store_product_transactions: 'Produits',
  payment_type_select_box: 'Sélectionnez le type de paiement',
  payment_description: 'Description',
  add_to_cart: 'Ajouter au panier',
  remove_from_cart: 'Retirer du panier',
  save: 'Enregistrer',
  bookings_cart_summary: 'Récapitulatif de la réservation',
  today: 'Aujourd\'hui',
  fill_booking_details_title: 'Remplissez le formulaire de réservation',
  create_an_account_question: 'Créez un compte et envoyez le mot de passe à l\'adresse email',
  value_is_incorrect: 'Cette valeur n\'est pas correcte.',
  first_available_date: 'Dates disponibles le',
  service_location_1: 'Adresse',
  service_location_2: 'Téléphone',
  service_location_3: 'Google Meet',
  service_location_4: 'Autre',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Localisation',
  booking_not_found: 'Aucune réservation n\'a pas été trouvée.',
  booking_canceled: 'La réservation a été annulée.',
  booking_canceled_fail: 'La réservation ne peut pas être annulée.',
  day: 'Jour',
  week: 'Semaine',
  month: 'Mois',
  year: 'An',
  booking_plural_1: 'Réservation',
  booking_plural_2: 'Réservations',
  booking_plural_3: 'Réservations',
  booking_plural_4: 'Réservations',
  booking_plural_5: 'Réservations',
  day_plural_1: 'Jour',
  day_plural_2: 'Jours',
  day_plural_3: 'Jours',
  day_plural_4: 'Jours',
  day_plural_5: 'Jours',
  week_plural_1: 'Semaine',
  week_plural_2: 'Semaines',
  week_plural_3: 'Semaines',
  week_plural_4: 'Semaines',
  week_plural_5: 'Semaines',
  month_plural_1: 'Mois',
  month_plural_2: 'Mois',
  month_plural_3: 'Mois',
  month_plural_4: 'Mois',
  month_plural_5: 'Mois',
  year_plural_1: 'An',
  year_plural_2: 'Ans',
  year_plural_3: 'Ans',
  year_plural_4: 'Ans',
  year_plural_5: 'Ans',
  tax: 'NIF',
  tax_prefix: 'Préfixe',
  get_company_data: 'Recherche',
  get_company_data_failed: 'Impossible d\'obtenir les données.',
  company_name: 'Nom de l\'entreprise',
  street_and_number: 'Rue et numéro',
  postcode: 'Code postal',
  city: 'Ville ou Localité',
  country: 'Pays',
  subscribe: 'S\'abonner',
  recurrent_fee_info: 'Facturé chaque',
  one_time_fee_info: 'Paiement unique.',
  new_account_title: 'Créer un nouveau compte',
  payment_data_title: 'Informations de paiement',
  billing_data_checkbox_question: 'Données de facturation',
  confirmation_modal_header: 'Confirmation recquise',
  confirmation_modal_warning: 'Êtes-vous certain de vouloir continuer?',
  no_way_to_undo_action: 'Cette action ne peut pas être annulée!',
  delete_payment_method: 'Supprimer la carte bancaire',
  add_payment_method: 'Ajouter une carte bancaire',
  fill_card_details: 'Remplissez les détails de paiement.',
  subscriptions: 'Abonnements',
  subscription: 'Abonnement',
  invoice_number: 'Facture',
  number: 'Numéro',
  status: 'État ',
  details: 'Détails',
  created_at: 'Créé',
  subscription_status_initiated: 'Impayé',
  subscription_status_active: 'Actif',
  subscription_status_inactive: 'Inactif',
  subscription_status_canceled: 'Annulé',
  ends_at: 'Validité',
  ends_at_default: 'Valable jusqu\'à la fin de la période',
  select_subscription_to_pay: 'Sélectionnez un abonnement pour payer.',
  pay_with_subscription: 'Payer par abonnement',
  bookings_paid_by_subscription_success_message: 'Payé',
  bookings_not_paid_by_subscription_error_message: 'Il n\'a pas été possible de payer la réservation avec l\'abonnement sélectionné.',
  stripe: 'Stripe',
  provider_tag: 'Méthode',
  card: 'Carte bancaire',
  cash: 'En espèces',
  transfer: 'Virement',
  promo_code_label: 'Coupon de réduction',
  promo_code_invalid_message: 'Le coupon de réduction est non valide.',
  payment_method_setup_error: 'Il y a un problème avec votre carte bancaire. Votre banque a refusé d\'autoriser la carte. Veuillez contacter votre banque.',
  active_subscription_missing: 'La réservation de ce service nécessite un abonnement actif. Vous n\'avez aucun abonnement à utiliser.',
  area_code: 'Indicatif pays',
  usage: 'Usage',
  create_account_in_stripe_failed: 'Données rejetées par l\'opérateur de paiement. Veuillez vérifier l\'exactitude des données (numéro d\'identification fiscale, numéro de téléphone, adresse e-mail).',
  additional_auth_required: 'Votre banque demande une autorisation supplémentaire',
  general_payment_error: 'Un problème est survenu lors de votre paiement. Contactez votre banque ou choisissez un autre mode de paiement.',
  subscription_set_successfully: 'Abonnement configuré correctement.',
  booking_created: 'Nouvelle réservation',
  booking_updated: 'Changement de réservation',
  booking_deleted: 'Suppression d\'une réservation',
  booking_reminder: 'Rappel de réservation',
  email_notifications: 'Notification par email',
  sms_notifications: 'Notifications par SMS',
  save_user_preferences: 'Enregistrer les paramètres',
  free_spots: 'Restant',
  no: 'Non',
  yes: 'Oui',
  p24_inactive: 'L\'opérateur Przelewy24 ne peut pas être utilisé. Veuillez contacter votre opérateur de paiement.',
  stripe_customer_invalid: 'Identifiant client Stripe non valide. Le mode de paiement ne peut pas être connecté.',
  stripe_resource_invalid: 'Le produit sélectionné n\'est pas disponible auprès de l\'opérateur de paiement.',
  account_blocked_message: 'Compte désactivé, contactez votre administrateur.',
  time_zone: 'Fuseau horaire',
  no_spots_left: 'Vous avez atteint la limite maximale de réservation.',
  employee_auth_required: 'Veuillez vous connecter à votre compte ou utiliser une adresse e-mail différente. Les comptes des employés nécessitent une connexion.',
  subpage_auth_required: 'Vous devez être connecté pour voir l\'onglet.',
  gross: 'Le montant brut.',
  subscription_login_info: 'Vous avez déjà un compte? Veuillez vous connecter ci-dessous pour remplir vos données.',
  change_date_time: 'Reprogrammer',
  promo_code_checkbox: 'J\'ai un code de réduction',
  booking_promo_code_label: 'Entrez le code de réduction',
  select_hour: 'Séléctionner l\'heure',
  id: 'Id',
  booking_rescheduled: 'La réservation a été modifiée.',
  booking_rescheduled_fail: 'La réservation ne peut pas être modifiée.',
  max_user_bookings_error: 'Vous avez dépassé le nombre maximum de réservations. Veuillez choisir un autre service ou contactez-nous.',
  in_total: 'Total',
  company: 'Professionnel',
  individual: 'Particulier',
  bulk_payment: 'Paiement groupé',
  make_bulk_payment: 'Paiement groupé',
  simple_store_product_confirmation_title: 'Remplissez le formulaire d\'achat',
  select_service_type: 'Sélectionnez une option de service',
  add_to_calendar_button_label: 'Ajouter à l\'Agenda',
  login_to_use_service_with_subscription: 'Veuillez vous connecter à votre compte pour prendre rendez-vous pour ce service.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'La date choisie n\'est pas disponible. Veuillez choisir une autre date libre. En cas de réservation de plusieurs dates, assurez-vous qu\'elles ne se chevauchent pas.',
  select_location_calendar_warning: 'Sélectionnez un emplacement pour charger les heures disponibles.',
  selectable_location_online_title: 'En ligne',
  change: 'Changer',
  show_less_hours_button: 'Montrer moins d\'heures',
  show_more_hours_button: 'Montrer plus d\'heures',
  add_another_button_title: 'Ajouter un autre',
  close: 'Fermer',
  selected_time_slots: 'Créneaux horaires sélectionnés',
  select_location: 'Sélectionner l\'emplacement',
  captcha_invalid_error_notification: 'La vérification du captcha a échoué. Essayez de recharger la page si le problème persiste.',
  verify_code_incorrect_error: 'Le code saisi est incorrect.',
  verify_code_too_many_requests: 'Nous ne pouvons pas générer un nouveau code pour le moment. Veuillez réessayer plus tard ou utiliser le code que nous vous avons déjà envoyé.',
  send_again_button_title: 'Envoyer de nouveau',
  verify_title: 'Vérifier',
  verify_code_title: 'Entrez le code de vérification',
  verify_code_description: 'Nous avons envoyé un code de vérification à 6 chiffres à votre adresse e-mail. Veuillez le saisir ci-dessous :',
  verification_invalid_error_notification: 'Vérification non valide. Réessayez plus tard.'
}
